import { useRequest, useLocalStorageState, useMount } from "ahooks";
import axios from "axios";
import SwiperCore, { Autoplay, EffectFade, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import { appearAnimation, useIsomorphicEffect } from "@pancakeswap/uikit";
import { useState } from "react";
import { set } from "lodash";
import { SubBannerKey } from "../../SubName";

const getBanner = () => {
  return "https://app.bitxpocket.com/pocket/getPocketBanner?type=" + `${SubBannerKey}`;
}

const testADDataItem = {
  id: 2,
  redirectUrl: "https://t.me/BTN10086",
  imageUrl: "https://d1l7ift7wyu6at.cloudfront.net/images/swap-banner02.jpg",
  top: 1,
  status: 1,
  createTime: 1663238511000,
  expiredTime: "2024-12-26 00:00:00",
};

type ADDataItem = typeof testADDataItem;

const ADList: ADDataItem[] =
  [
    {
      "id": 8,
      "redirectUrl": "https://t.me/BTN10086",
      "imageUrl": "https://d1l7ift7wyu6at.cloudfront.net/images/swap-banner01.jpg",
      "top": 1,
      "status": 1,
      "createTime": 1663238511000,
      "expiredTime": "2024-12-26 00:00:00",
    },
    {
      "id": 9,
      "redirectUrl": "https://t.me/BTN10086",
      "imageUrl": "https://d1l7ift7wyu6at.cloudfront.net/images/swap-banner02.jpg",
      "top": 0,
      "status": 1,
      "createTime": 1663238511000,
      "expiredTime": "2024-12-26 00:00:00",
    },
  ]
  ;
// 过期时间 1 天
// const expireTime = 1000 * 60 * 60 * 24;

const expireTime = 1000 * 60 * 10; // 10分钟


const BannerPlaceHolder = styled.div`
  position: relative;
  // width: 1112px;
  max-width: 100vw;
  margin-top: 0px;
`;

const StyledSwiper = styled(Swiper)`
  position: relative;
  overflow: visible;
  opacity: 1;
  animation: ${appearAnimation} 0.3s ease-in-out 0.7s forwards;
  .swiper-pagination {
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 108px;
    bottom: 12px;
  }
  .swiper-pagination-bullet {
    background-color: white;
    flex-basis: 12px;
    height: 1px;
    margin: 0 !important;
    border-radius: 0px;
    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }
  }
`;

export const ADSwiper: React.FC = () => {
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null);
  const [adList, setAdList] = useLocalStorageState<ADDataItem[]>("SwapADList", {
    defaultValue: [],
  });
  const [adTime, setAdTime] = useLocalStorageState<number>("SwapADTime", {
    defaultValue: 0,
  });
  const { run } = useRequest(
    () => {
      return axios.get(getBanner());
    },
    {
      manual: true,
      onSuccess: (res) => {
        if (res?.data?.rows) {
          console.log("res.data.data", res.data.rows);
          setAdList(res.data.rows);
          setAdTime(Date.now());
        } else {
          setAdList(ADList);
          setAdTime(Date.now());
        }
      },
    }
  );

  const sortedADList = ADList.sort((a, b) => {
    return b.top - a.top;
  });

  const loadbanner = () => {
    // 将ADList里面的数据按照权重从大到小排序，然后取前三个，如果不足三个，就取全部。
    // 这样可以保证每次刷新的时候，都是按照权重从大到小的顺序展示广告。
    const bannerSorted = sortedADList.slice(0, 6);
    setAdList(bannerSorted);
    setAdTime(Date.now());
  }

  useMount(() => {
    if (Date.now() - adTime > expireTime) {
      run();
      // loadbanner();
    }
  });
  useIsomorphicEffect(() => {
    if (swiperRef) {
      if (adList.length > 1 && !swiperRef.autoplay?.running) {
        swiperRef.autoplay?.start();
      } else if (adList.length <= 1 && swiperRef.autoplay?.running) {
        swiperRef.autoplay?.stop();
      }
    }
  }, [adList, swiperRef]);

  return (
    <BannerPlaceHolder>
      <StyledSwiper
        onSwiper={setSwiperRef}
        modules={[Autoplay, Pagination, EffectFade]}
        spaceBetween={0}
        observer
        slidesPerView={1}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        speed={500}
        autoplay={{ delay: 5000, pauseOnMouseEnter: true, disableOnInteraction: false }}
        loop
        pagination={{ clickable: true }}
      >
        {adList.map((item, index) => {
          const childKey = `Banner${index}`;
          return (
            <SwiperSlide key={childKey}>
              {item.redirectUrl ? (
                <a href={item.redirectUrl} style={{ display: "block", width: "100%" }} target="_blank" rel="noreferrer">
                  <img src={item.imageUrl} alt="" style={{ width: "100%" }} />
                </a>
              ) : (
                <img src={item.imageUrl} alt="" style={{ width: "100%" }} />
              )}
            </SwiperSlide>
          );
        })}
      </StyledSwiper>
    </BannerPlaceHolder>
  );
};


